import { render, staticRenderFns } from "./dayName.vue?vue&type=template&id=e1867cea&scoped=true&"
import script from "./dayName.vue?vue&type=script&lang=js&"
export * from "./dayName.vue?vue&type=script&lang=js&"
import style0 from "./dayName.vue?vue&type=style&index=0&id=e1867cea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1867cea",
  null
  
)

export default component.exports